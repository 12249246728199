import { createStore } from 'vuex'
import mutations from './mutations'
import * as getters from './getters'
import state from './state'

export default createStore({
  state,
  mutations,
  getters,
})
