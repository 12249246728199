<template>
   <router-view />
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { getWebsiteInfo } from '@/apis/home/website'
export default {
   methods: {
      async getWebsiteInfoHandler(){
         const res = await getWebsiteInfo()
         this.setConfig(res.data)
      },
      ...mapMutations({
         setConfig: 'SET_CONFIG'
      })
   },
   created(){
      this.getWebsiteInfoHandler()
   }
}
</script>
<style lang="stylus">
@import '~@/styles/ui.styl'
@import '/fonts/iconfont/iconfont.css'
</style>
