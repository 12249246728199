import config from '@/config/config'
const storage = {
    setLocalData(key, data){
        return localStorage.setItem(config.key + key, data)
    },
    getLocalData(key){
        return localStorage.getItem(config.key + key)
    },
    clear(){
        localStorage.clear()
    }
}

export default storage