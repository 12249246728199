import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
import router from '@/router'
import storage from '@/utils/storage'
import config from '@/config/config'

const request = axios.create({
    baseURL: config.apiDomain,
    timeout: 10000,
})

let count = 0


//请求拦截器
request.interceptors.request.use(
    confirm => {
        const token = storage.getLocalData('token')
        const url = confirm.url
        if(token){
            confirm.headers.Authorization = 'Bearer ' + token
        }
        if(url == '/task/uploadTaskFile'){
            confirm.headers.ContentType = 'multipart/form-data'
            const file = confirm.data.file
            const task_id = confirm.data.data.task_id
            const emp_id = confirm.data.data.emp_id
            let param = new FormData() 
            param.append('file', file)
            param.append('task_id', task_id)
            param.append('emp_id', emp_id)
            confirm.data = param
        }else{
            if(url.indexOf('/system/upload') > -1 || url.indexOf('/product/upload') > -1){
                confirm.headers.ContentType = 'multipart/form-data'
                const file = confirm.data
                let param = new FormData() 
                param.append('file', file)
                confirm.data = param
            }
        }
        return confirm
    },
    error =>{
        return Promise.error(error); 
    }
)

// 响应拦截器
request.interceptors.response.use( 
    response => {
        return new Promise((resolve, reject) => {
            const res = response.data
            if(res.code == 0){
                count = 0
                return resolve(res.data)
            }
            if(res.code > 1 ){
                count++
                if(count == 1){
                    message.error(res.msg)
                    if(res.code == 10000 || res.code == 10001){
                        return setTimeout(() => {
                            router.push({path: '/login'})
                        }, 1000)
                    }
                }
            }else{
                message.error(res.msg);
                resolve(false)
            }
        })
    },
    error => {
        message.warning(error)
    }
)

export default request