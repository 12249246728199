import { createRouter, createWebHashHistory } from 'vue-router'
import storage from '@/utils/storage'

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: 'Solar Power That Works | Solar & Battery Solutions Australia'
    },
    component: () => import('@/views/Index.vue'),
    redirect: '/home',
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'home',
        name: 'Home',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Home.vue'),
      },
      {
        path: 'diy',
        name: 'Diy store',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Diy.vue'),
      },
      {
        path: 'solutions',
        name: 'Home solutions',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Solutions.vue'),
      },
      {
        path: 'aboutus',
        name: 'Home about us',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Aboutus.vue'),
      },
      {
        path: 'contactus',
        name: 'Home contact us',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Contactus.vue'),
      },
      {
        path: 'products/:product_id',
        name: 'Home product',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Product.vue'),
      },
      {
        path: 'recommend',
        name: 'Home recomended',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Recommended.vue'),
      },
      {
        path: 'recommended_detail',
        name: 'Home recomended detail',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/RecommendedDetail.vue'),
      },
      {
        path: 'order',
        name: 'Home order',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia'
        },
        component: () => import('@/views/Order.vue'),
      },
    ]
  },
  {
    path: '/manage',
    name: 'Manage',
    meta: {
      title: 'Solar Power That Works | Solar & Battery Solutions Australia',
      auth: true
    },
    component: () => import('@/views/manage/Index.vue'),
    redirect: '/manage/admin',
    children: [
      {
        path: 'admin',
        name: 'Admin',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia',
          auth: true
        },
        component: () => import('@/views/manage/admin/Index.vue'),
        redirect: '/manage/admin/dashboard',
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/dashboard/Index.vue'),
          },
          {
            path: 'administrator',
            name: 'Administrator',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/administrator/Index.vue'),
          },
          {
            path: 'order',
            name: 'Order',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/order/Index.vue'),
          },
          {
            path: 'personnel',
            name: 'Personnel',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/personnel/Index.vue'),
            redirect: '/manage/admin/personnel/department',
            children: [
              {
                path: 'department',
                name: 'Department',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/personnel/department/Index.vue'),
              },
              {
                path: 'employee',
                name: 'Employee',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/personnel/employee/Index.vue'),  
              },
            ]
          },
          {
            path: 'recommended',
            name: 'Recommended',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/recommended/Index.vue'),
          },
          {
            path: 'task',
            name: 'Task',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/task/Index.vue'),
          },
          {
            path: 'product',
            name: 'Product',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/product/Index.vue'),
            redirect: '/manage/admin/product/product',
            children: [
              {
                path: 'product',
                name: 'Product',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/product/product/Index.vue'),
              },
              {
                path: 'product_class',
                name: 'ProductClass',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/product/product_class/Index.vue'),
              },
              {
                path: 'region',
                name: 'Region',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/product/region/Index.vue'),
              },
              {
                path: 'brand',
                name: 'Brand',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/product/brand/Index.vue'),
              },
            ]
          },
          {
            path: 'website',
            name: 'Website',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/admin/website/Index.vue'),
            redirect: '/manage/admin/website/website_setting',
            children: [
              {
                path: 'website_setting',
                name: 'Website setting',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/website/websiteSetting/Index.vue'),
              },
              {
                path: 'aboutus',
                name: 'About us',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/website/aboutus/Index.vue'),
              },
              {
                path: 'contactus',
                name: 'Contact us',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/website/contactus/Index.vue'),
              },
              {
                path: 'solutions',
                name: 'Solutions',
                meta: {
                  title: 'Solar Power That Works | Solar & Battery Solutions Australia',
                  auth: true
                },
                component: () => import('@/views/manage/admin/website/solutions/Index.vue'),
              },
            ]
          },
        ]
      },
      {
        path: 'employees',
        name: 'Employees',
        meta: {
          title: 'Solar Power That Works | Solar & Battery Solutions Australia',
          auth: true
        },
        component: () => import('@/views/manage/employee/Index.vue'),
        redirect: '/manage/employees/tasks',
        children: [
          {
            path: 'tasks',
            name: 'Tasks',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/employee/task/Index.vue'),
          },
          {
            path: 'orders',
            name: 'Orders',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/employee/order/Index.vue'),
          },
          {
            path: 'calendar',
            name: 'Calendar',
            meta: {
              title: 'Solar Power That Works | Solar & Battery Solutions Australia',
              auth: true
            },
            component: () => import('@/views/manage/employee/calendar/Index.vue'),
          }
        ]
      }
    ]
  },
  { 
    path: '/:catchAll(.*)*', 
    name: 'NotFound', 
    component: () => import('@/views/NotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
   if(to.meta.auth){
      const token = storage.getLocalData('token')
      if(!token) return next('/login')
   }
   return next()
})

export default router
