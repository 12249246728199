import { createApp } from 'vue'
import Antd, { message } from 'ant-design-vue'
import * as antIcons from '@ant-design/icons-vue'
import App from './App.vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import store from './store'

message.config({
  top: `200px`,
  duration: 1,
  maxCount: 3,
});

const app = createApp(App)
Object.keys(antIcons).forEach(key => {
  app.component(key, antIcons[key])
})
app.config.globalProperties.$antIcons = antIcons
app.use(PerfectScrollbar).use(store).use(router).use(Antd).mount('#app')